// Include default ABWeb CMS public styles
@import '../../../../../../webroot/assets/src/less/default.less';
// Custom variables
@import (optional) 'variables.less';
// Animations
@import (optional) 'animations.less';

/*--------------------- TYPOGRAPHY */

h1, h2, h3 {
    text-transform: uppercase;
}

h1 {
    font-size: 35px;
    line-height: 35px;
}

h2 {
    font-size: 18px;
}

/*--------------------- HEADER AND NAV */

body #viewport {
    padding-top: 0;
}

body.cms-menu-visible {
    #viewport {
        padding-top: 48px !important;
    }

    header {
        top: 48px;
    }

    #main-nav {
        top: auto !important;
    }
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    z-index: 1999;
    box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.15);

    & > div.container {
        position: relative;
    }

    #logo {
        width: @header-logo-width + @grid-gutter-size;

        div.container {
            width: @header-logo-width + @grid-gutter-size;
        }
    }

    .header-extra {
        position: absolute;
        top: 15px;
        right: 30px;
        width: 300px;
    }

    #main-nav {
        position: absolute;
        top: auto;
        left: auto;
        bottom: 0;
        right: 0;
        width: 752px;

        .navbar {
            .navbar-brand,
            .navbar-nav li a {
                position: relative;
                z-index: 11;
                font-family: @heading-font, @heading-typography;
                font-weight: @heading-weight;
                text-transform: uppercase;
            }

            .navbar-brand,
            .navbar-nav > li > a {
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -1px;
                    width: 2px;
                    height: 20px;
                    background: @navbar-fore-colour;
                    margin-top: -10px;
                    z-index: 10;
                }
            }

            .navbar-brand.navbar-brand-active,
            .navbar-nav > li.active > a,
            .navbar-brand:hover,
            .navbar-nav > li > a:hover,
            .navbar-brand:focus,
            .navbar-nav > li > a:focus {
                background: #fff;
                box-shadow: 0px -3px 2px rgba(0, 0, 0, 0.15);
            }

            .navbar-nav > li:last-child > a {
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: -1px;
                    width: 2px;
                    height: 20px;
                    background: @navbar-fore-colour;
                    margin-top: -10px;
                    z-index: 10;
                }
            }

            .navbar-nav {
                li.dropdown {
                    .dropdown-menu {
                        padding: 0px;
                        .border-radius(0px);
                        border-top: none;

                        li {
                            a {
                                color: @dropdown-fore-colour;
                                background: @dropdown-back-colour;

                                &:hover {
                                    color: @dropdown-hover-fore-colour;
                                    background: @dropdown-hover-back-colour;
                                }
                            }

                            &.active {
                                > a {
                                    color: @dropdown-active-fore-colour;
                                    background: @dropdown-active-back-colour;

                                    &:hover {
                                        color: @dropdown-hover-fore-colour;
                                        background: @dropdown-hover-back-colour;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        #main-nav {
            width: 680px;

            .navbar {
                .navbar-brand,
                .navbar-nav li a {
                    padding: 0px 9px;
                }
            }
        }
    }

    @media (max-width: @screen-sm-max) {
        #logo,
        #logo div.container {
            width: 230px;
        }

        #main-nav {
            width: 100%;
            background: transparent;

            .navbar .navbar-brand {
                display: none;
            }
        }
    }
}

.nav-pills {
    li > a {
        color: @submenu-fore-colour;
        background-color: @submenu-back-colour;
        font-weight: @heading-weight;
        font-family: @heading-font, @heading-typography;
        text-transform: uppercase;

        &:hover,
        &:focus {
            color: @submenu-hover-fore-colour;
            background-color: @submenu-hover-back-colour;
        }
    }

    li.active > a {
        &,
        &:hover,
        &:focus {
            color: @submenu-active-fore-colour;
            background-color: @submenu-active-back-colour;
        }
    }

    &.submenu {
        margin-top: 0;
        margin-bottom: 40px;
    }
}

.breadcrumb {
    background: none;
    text-align: right;
    margin-bottom: 0;

    & > li > a {
        &:hover,
        &:focus {
            color: #fff;
            text-decoration: none;
        }
    }

    & > li+li:before {
        content: '»';
        color: #fff;
    }

    & > .active {
        color: #eee;
    }
}

/*--------------------- CONTENT */

div#wrap {
    position: relative;
    padding-top: (@header-height + @header-bottom-spacing);
    padding-bottom: (@footer-height + 21px);
    background: #000;

    div.background-slider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('/img/slides/1.jpg') no-repeat center @header-height;
        background-size: 100% auto;
    }

    & > div.container {
        position: relative;
        z-index: 1997;
    }
}

section.top-content {
    padding: 10px 40px;

    background: rgba(80, 86, 99, 0.85);
    color: #fff;
}

body.home-page {
    section.top-content {
        padding: 40px;
    }
}

section.content {
    padding: 40px;

    background: #fff url('/img/content-bg.jpg') no-repeat top right;
}

ul.specialities {
    margin: 0;
    padding: 0;

    li {
        list-style-position: inside;
        list-style-type: square;
        padding: 5px 0px;
        border-bottom: 1px dotted #ccc;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
}

/*--------------------- CALL TO ACTIONS */

div.call-to-actions,
div.subcategories,
div.products {
    .action,
    .subcategory,
    .product {
        position: relative;
        display: block;
        height: 229px;
        width: 100%;
        padding: 30px;
        margin: 5px 0;
        overflow: hidden;

        background-color: #000;
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        color: #fff;
        font-family: @heading-font, @heading-typography;
        font-weight: @heading-weight;
        font-size: 28px;
        text-transform: uppercase;

        -webkit-transition: color 350ms ease;
        transition: color 350ms ease;

        span {
            position: relative;
            z-index: 12;
            text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

            -webkit-transition: text-shadow 350ms ease;
            transition: text-shadow 350ms ease;
        }

        &::after {
            content: '\E433';

            position: absolute;
            z-index: 11;
            top: auto;
            bottom: 0;
            right: 0;
            height: 24px;
            width: 24px;

            background: #FFCE00;
            color: #001D8F;
            opacity: 1;
            font-family: 'Glyphicons Regular';
            font-size: 14px;
            font-style: normal;
            font-weight: normal;
            line-height: 24px;
            text-align: center;
            text-indent: 0px;
            vertical-align: middle;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            -webkit-transition: height 350ms ease, width 350ms ease, text-indent 350ms ease, opacity 350ms ease;
            transition: height 350ms ease, width 350ms ease, text-indent 350ms ease, opacity 350ms ease;
        }

        &:hover,
        &:focus {
            color: #363636;
            text-decoration: none;

            span {
                text-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
            }

            &::after {
                width: 100%;
                height: 100%;
                text-indent: 2000px;
                opacity: 0.85;
            }
        }

        &.get-a-quote {
            background-image: url('/img/buttons/quote.jpg');
        }

        &.products {
            background-image: url('/img/buttons/products.jpg');
        }

        &.contact-us {
            background-image: url('/img/buttons/contact.jpg');
        }
    }

    .subcategory,
    .product {
        line-height: 1.00em;
        text-align: left;
        font-size: 1.35em;
    }
}

/*--------------------- TOP FOOTER */

section.top-footer {
    margin-top: 15px;
    padding: 40px;

    background: #1D1F24;
    color: #d5d8e0;

    a {
        &:hover,
        &:focus {
            color: #fff;
            text-decoration: none;
        }
    }

    ul.footer-nav {
        padding: 0;

        li {
            list-style: none;

            a {
                display: block;
                text-transform: uppercase;
                color: #d5d8e0;
                padding: 4px 0px;
                border-bottom: 1px dotted #43464a;

                &:hover,
                &:focus {
                    color: #fff;
                    text-decoration: none;
                }
            }

            &:last-child {
                a {
                    border-bottom: 0;
                }
            }
        }
    }

    hr {
        border-top-color: #4e5156;
    }

    .border-sep {
        border-left: 1px solid #4e5156;

        @media (max-width: @screen-xs-max) {
            margin-top: 20px;
            border-left: 0;
        }
    }

    ul.ticks {
        margin-left: 0px;
        padding-left: 20px;

        li {
            list-style-image: url('/img/frontend/footer_bullet.jpg');
            list-style-position: outside;
            padding-bottom: 12px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1em;
        }
    }

    .social-link {
        display: inline-block;
        width: 31px;
        height: 30px;
        margin-right: 5px;
        background-repeat: no-repeat;

        &.linkedin {
            background-image: url('/img/frontend/linkedin_ico.jpg');
        }
        &.twitter {
            background-image: url('/img/frontend/tweeter_ico.jpg');
        }
        &.facebook {
            background-image: url('/img/frontend/facebook_ico.jpg');
        }
        &.youtube {
            background-image: url('/img/frontend/youtube_ico.jpg');
        }
    }
}

/*--------------------- BOTTOM FOOTER */

footer {
    position: relative;
    padding: 0;
    z-index: 1998;

    background: none;

    a:hover,
    a:focus {
        color: #fff;
    }

    div.footer-inner {
        padding: 40px;
        background: @footer-back-colour;
        min-height: @footer-height;
    }
}

/*--------------------- FORMS */

.form-group {
    div.error-message {
        .flatten();
    }
}

/*--------------------- NEWS */

.news-widget {
    h3 {
        margin-top: 0;

        a {
            color: #fff;

            &:hover,
            &:focus {
                color: #ccc;
                text-decoration: none;
            }
        }
    }

    ul.articles {
        padding: 0;

        li {
            display: block;
            padding: 5px 0px;
            border-bottom: 1px dotted #949494;

            a {
                span.date {
                    font-weight: bold;
                }

                span.title {
                    color: #fff;
                }

                &:hover,
                &:focus {
                    span.date {
                        color: @link-colour;
                    }

                    span.title {
                        color: #ccc;
                    }

                    text-decoration: none;
                }
            }

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 5px;
                border-bottom: 0;
            }
        }
    }

    .text-right {
        a {
            &:hover,
            &:focus {
                color: #fff;
                text-decoration: none;
            }
        }
    }

    @media (max-width: @screen-sm-max) {
        padding-bottom: 20px;
        border-bottom: 1px solid #666;
        margin-bottom: 20px;
    }
}

/*--------------------- QUOTE FORM */

section.quote-form {
    background: @header-back-colour;
    padding: 40px;
    color: @header-fore-colour;

    h3 {
        color: @header-fore-colour;
        margin: 0px 0px 20px;
    }

    fieldset legend {
        border-bottom: 1px solid darken(@header-back-colour, 10%);
    }

    img.measurement-diagram {
        border: 1px solid darken(@header-back-colour, 10%);
    }
}

@import 'colorbox.less';
